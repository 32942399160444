import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useDjangoApi } from "components/fetch/useDjangoApi";
import {
  useWatchlistsQuery,
  watchlistsEndpoint,
} from "components/fetch/useWatchlistsQuery";
import { useWatchlistQuery } from "components/fetch/useWatchlistQuery";
import {
  WatchlistId,
  WatchlistProject,
  WatchlistSummary,
  bookmarksId,
  bookmarksName,
  useWatchlists,
} from "./WatchlistContext";

export const useWatchlist = (watchlist?: WatchlistSummary) => {
  const api = useDjangoApi();
  const queryClient = useQueryClient();
  const watchlistsQuery = useWatchlistsQuery();
  const watchlistQuery = useWatchlistQuery(watchlist);

  const setWatchlistUnreadCount = (
    watchlistId: WatchlistId,
    unread: number
  ) => {
    if (!watchlistsQuery.data) return;

    const watchlist = watchlistsQuery.data.find(
      (w: WatchlistSummary) =>
        w.id === watchlistId ||
        (w.name === bookmarksName && watchlistId === bookmarksId)
    );

    if (!watchlist) return;

    const index = watchlistsQuery.data.indexOf(watchlist)!;

    const data = structuredClone(watchlistsQuery.data);
    data[index] = { ...watchlist, unread };

    queryClient.setQueryData(watchlistsQuery.queryKey, data);
  };

  const setProjectState = (project: WatchlistProject, state: any) => {
    if (!watchlistQuery.data) return;
    const index = watchlistQuery.data.projects.indexOf(project);

    if (index >= 0) {
      const data = structuredClone(watchlistQuery.data);
      data.projects[index] = { ...project, ...state };

      queryClient.setQueryData(watchlistQuery.queryKey, data);
    }
  };

  const setAllProjectsState = (state: any) => {
    if (!watchlistQuery.data) return;
    queryClient.setQueryData(watchlistQuery.queryKey, {
      ...watchlistQuery.data,
      projects: watchlistQuery.data.projects.map((p: WatchlistProject) => ({
        ...p,
        ...state,
      })),
    });
  };

  const setProjectReadState = (project: WatchlistProject, read: boolean) => {
    if (!watchlist) return;
    const watchlistId =
      watchlist.name === bookmarksName ? bookmarksId : watchlist.id;
    setWatchlistUnreadCount(watchlistId, watchlist.unread + (read ? -1 : 1));
    setProjectState(project, { read });
    api
      .postDjangoApi(
        `${watchlistsEndpoint}${watchlistId}/${read ? "read" : "unread"}-project/${project.id}/`
      )
      .catch((error: unknown) => {
        queryClient.invalidateQueries({ queryKey: watchlistQuery.queryKey });
        console.error(error);
      });
  };

  const setAllProjectsReadState = (read: boolean) => {
    if (!watchlist) return;
    if (watchlist.unread === 0 && read) return;
    if (watchlist.unread === watchlist.projects && !read) return;
    const watchlistId =
      watchlist.name === bookmarksName ? bookmarksId : watchlist.id;
    setWatchlistUnreadCount(watchlistId, read ? 0 : watchlist.projects);
    setAllProjectsState({ read });
    api
      .postDjangoApi(
        `${watchlistsEndpoint}${watchlistId}/${read ? "read" : "unread"}-all-projects/`
      )
      .catch((error: unknown) => {
        queryClient.invalidateQueries({ queryKey: watchlistQuery.queryKey });
        console.error(error);
      });
  };

  return {
    ...watchlistQuery,
    readProject: (project: WatchlistProject) =>
      setProjectReadState(project, true),
    unreadProject: (project: WatchlistProject) =>
      setProjectReadState(project, false),
    readAllProjects: () => setAllProjectsReadState(true),
    unreadAllProjects: () => setAllProjectsReadState(false),
  };
};

export const useWatchlistById = (watchlistId: WatchlistId) => {
  const { getWatchlistById } = useWatchlists();
  const watchlist = getWatchlistById(watchlistId);

  return useWatchlist(watchlist);
};

export const useCurrentWatchlist = () => {
  const { watchlistId } = useParams();
  const id = isNaN(Number(watchlistId))
    ? (watchlistId as WatchlistId)
    : Number(watchlistId);

  return useWatchlistById(id);
};

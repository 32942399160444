import { WatchlistSummary } from "components/watchlist/WatchlistContext";
import { useWatchlist } from "components/watchlist/useWatchlist";
import { LinkButton } from "components/button/LinkButton";
import { NotificationCount } from "./NotificationCount";

interface WatchlistUnreadProjectsInfoProps {
  watchlist: WatchlistSummary;
}

export const WatchlistUnreadProjectsInfo = ({
  watchlist,
}: WatchlistUnreadProjectsInfoProps) => {
  const { readAllProjects, unreadAllProjects } = useWatchlist(watchlist);
  const plural = watchlist.unread !== 1;
  const active = watchlist.unread > 0;

  const toggleAll = () => {
    if (active) {
      readAllProjects();
    } else {
      unreadAllProjects();
    }
  };

  return (
    <div className="rounded-5 my-3" style={{ background: "white" }}>
      <div className="d-flex gap-3 p-3">
        <NotificationCount
          height={40}
          width={40}
          active={active}
          count={active ? watchlist.unread : undefined}
        />
        <div className="d-flex flex-column align-items-start">
          <span>
            There {plural ? "are" : "is"} <strong>{watchlist.unread}</strong>{" "}
            unread project{plural ? "s" : ""} in your watchlist.
          </span>
          <LinkButton onClick={toggleAll}>
            {active
              ? "Mark all projects as read"
              : "Mark all projects as unread"}
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

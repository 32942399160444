import { ProjectLink } from "components/project/ProjectLink";
import { useCurrentWatchlist } from "components/watchlist/useWatchlist";
import { WatchlistProject } from "components/watchlist/WatchlistContext";

interface WatchlistProjectLinkProps {
  project: WatchlistProject;
}

export const WatchlistProjectLink = ({
  project,
}: WatchlistProjectLinkProps) => {
  const { readProject } = useCurrentWatchlist();
  return (
    <ProjectLink
      project={project}
      text={project.reference}
      onClick={() => readProject(project)}
      flyTo
    />
  );
};

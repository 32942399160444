import {
  Watchlist,
  WatchlistSummary,
  bookmarksName,
} from "components/watchlist/WatchlistContext";
import { useDjangoQuery } from "./useDjangoQuery";
import { watchlistsEndpoint } from "./useWatchlistsQuery";

export const bookmarksEndpoint = watchlistsEndpoint + "bookmarks/";
export const projectsPerWatchlist = 1000;

export const useWatchlistQuery = (watchlist?: WatchlistSummary) =>
  useDjangoQuery<Watchlist>(
    watchlist?.name === bookmarksName
      ? // limit projects on any watchlist detail view
        `${bookmarksEndpoint}?page_size=${projectsPerWatchlist}`
      : `${watchlistsEndpoint}${watchlist?.id}/?page_size=${projectsPerWatchlist}`
  );

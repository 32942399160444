import { WatchlistProject } from "components/watchlist/WatchlistContext";
import { useCurrentWatchlist } from "components/watchlist/useWatchlist";
import { NotificationCount, NotificationCountProps } from "./NotificationCount";

interface WatchlistProjectReadUnreadButtonProps {
  project: WatchlistProject;
}

export const WatchlistProjectReadUnreadButton = ({
  project,
  ...props
}: WatchlistProjectReadUnreadButtonProps & NotificationCountProps) => {
  const { readProject, unreadProject } = useCurrentWatchlist();

  const onClick = () => {
    if (project.read) {
      unreadProject(project);
    } else {
      readProject(project);
    }
  };

  return (
    <button
      type="button"
      className="btn btn-read-unread"
      onClick={onClick}
      onMouseLeave={(e) => e.currentTarget.blur()}
    >
      <NotificationCount active={!project.read} {...props} />
    </button>
  );
};

import { useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format, parse, parseISO } from "date-fns";
import {
  Watchlist,
  WatchlistProject,
  bookmarksName,
} from "components/watchlist/WatchlistContext";
import { RemoveFromWatchlistButton } from "./RemoveFromWatchlistButton";
import { WatchlistProjectDateTimeCell } from "./WatchlistProjectDateTimeCell";
import { DATE_FORMAT } from "utils/constants/date";
import { ReactComponent as AlertIcon } from "assets/other-icons/alert.svg";
import { ReactComponent as OpportunitiesIcon } from "assets/other-icons/light-bulb.svg";
import { WatchlistProjectLink } from "./WatchlistProjectLink";
import { WatchlistProjectTableHeader } from "./WatchlistProjectTableHeader";
import { WatchlistProjectReadUnreadButton } from "./WatchlistProjectReadUnreadButton";
import { projectsPerWatchlist } from "components/fetch/useWatchlistQuery";

const columnHelper = createColumnHelper<WatchlistProject>();
const columns = [
  columnHelper.accessor("read", {
    header: "",
    cell: (info) => (
      <WatchlistProjectReadUnreadButton project={info.row.original} />
    ),
    enableSorting: false,
  }),
  columnHelper.accessor("reference", {
    header: "Project Reference",
    cell: (info) => <WatchlistProjectLink project={info.row.original} />,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("title", {
    header: "Project Name",
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("organisation", { header: "Organisation" }),
  columnHelper.accessor(
    (row) => parse(row.start_date, DATE_FORMAT, new Date()),
    {
      id: "startDate",
      header: "Start Date",
      cell: (info) => format(info.getValue(), "dd/M/yyyy"),
      sortingFn: "datetime",
      sortDescFirst: false,
    }
  ),
  columnHelper.accessor((row) => parse(row.end_date, DATE_FORMAT, new Date()), {
    id: "endDate",
    header: "End Date",
    cell: (info) => format(info.getValue(), "dd/M/yyyy"),
    sortingFn: "datetime",
    sortDescFirst: false,
  }),
  columnHelper.accessor("clashes", {
    header: () => (
      <AlertIcon
        width={26}
        height={26}
        className="icon-clash active"
        title="Total # project clashes"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      />
    ),
    cell: (info) => <strong>{info.getValue()}</strong>,
  }),
  columnHelper.accessor("opportunities", {
    header: () => (
      <OpportunitiesIcon
        width={28}
        height={28}
        className="icon-opportunity active"
        title="Total # project opportunities"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      />
    ),
    cell: (info) => <strong>{info.getValue()}</strong>,
  }),
  columnHelper.accessor((row) => parseISO(row.date_added), {
    id: "dateAdded",
    header: "Date Added to Watchlist",
    cell: (info) => <WatchlistProjectDateTimeCell value={info.getValue()} />,
    sortingFn: "datetime",
  }),
  columnHelper.accessor((row) => row, {
    id: "remove",
    header: "",
    cell: (info) => <RemoveFromWatchlistButton project={info.getValue()} />,
    enableSorting: false,
  }),
];

export interface WatchlistProjectTableProps {
  watchlist: Watchlist;
}

export const WatchlistProjectTable = ({
  watchlist,
}: WatchlistProjectTableProps) => {
  const projects = useMemo(
    () => watchlist.projects.slice(0, projectsPerWatchlist),
    [watchlist.projects]
  );
  const table = useReactTable({
    data: projects,
    columns: watchlist.name === bookmarksName ? columns : columns.slice(0, -1), // omit 'Remove from watchlist'
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "dateAdded",
          desc: true,
        },
      ],
    },
  });

  return watchlist.projects.length > 0 ? (
    <div className="watchlist-table-container rounded-5 py-1">
      <table className="table table-borderless table-striped">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <WatchlistProjectTableHeader key={header.id} header={header} />
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p>There are no projects in this watchlist.</p>
  );
};

import { ReactComponent as BellIcon } from "assets/other-icons/bell.svg";
import classNames from "classnames";

export interface NotificationCountProps {
  count?: number;
  active?: boolean;
  width?: number;
  height?: number;
}

export const NotificationCount = ({
  count,
  active = false,
  width = 28,
  height = 28,
}: NotificationCountProps) => (
  <div
    className={classNames("notification-count", {
      "notification-count-active": active,
    })}
    style={{ width, height }}
  >
    <BellIcon width={width} height={height} />
    {count !== undefined && (
      <small className="text-center user-select-none w-100 fw-700">
        {count >= 100 ? "!" : count}
      </small>
    )}
  </div>
);

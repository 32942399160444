import { useQuery } from "@tanstack/react-query";
import { djangoApi } from "utils/djangoApi";
import { AxiosError } from "axios";
import { useAuth } from "components/auth/useAuth";
import { useErrorModal } from "components/modal/useErrorModal";

async function djangoFetcher<T>(url: string): Promise<T> {
  const response = await djangoApi.get(url);
  return response.data;
}

export function useDjangoQuery<T>(
  url: string,
  optionsOverride?: Parameters<typeof useQuery<T>>[2]
) {
  const { isAuthenticated, logout } = useAuth();
  const { setErrorModal } = useErrorModal();

  const queryKey = ["django", url];
  const queryFn = () => djangoFetcher<T>(url);
  const options = {
    staleTime: Infinity,
    onError: (error: unknown) => {
      const isAuthError =
        error instanceof AxiosError &&
        [401, 403].includes(error.response?.status ?? -1);
      if (isAuthenticated && isAuthError) {
        setErrorModal({
          title: "Request failed",
          content: "Your session was expired. Please log in again.",
        });
        logout();
      }
    },
    ...optionsOverride,
  };

  return {
    queryKey,
    ...useQuery<T>(queryKey, queryFn, options),
  };
}
